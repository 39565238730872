import SkeletonLoader from "@aptedge/lib-ui/src/components/SkeletonLoader/SkeletonLoader";
import MultiStepAnswer from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/MultiStepAnswer";
import Header from "@aptedge/lib-ui/src/features/AiAnswer/components/Header/Header";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateSearchQuery } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { FC, useEffect, useRef } from "react";

interface Props {
  questionWrapperComponent?: React.ElementType;
  showFirstQueryTitle?: boolean;
  questionQueryTitleClass?: string;
  hideHeader?: boolean;
  hideSuggestedQuestions?: boolean;
  showQuestionFooter?: boolean;
}

const AiAnswer: FC<Props> = ({
  questionWrapperComponent,
  showFirstQueryTitle,
  questionQueryTitleClass,
  hideHeader,
  hideSuggestedQuestions,
  showQuestionFooter
}) => {
  const dispatch = useAppDispatch();
  const {
    answer,
    isAnswerLoading,
    suggestedFollowupQuestions
  } = useAppSelector((state) => state.answerGPT);

  const containerRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current?.scrollHeight;
    }
  }, [answer]);

  const onFollowupClicked = (followup: string): void => {
    dispatch(updateSearchQuery(followup));
  };

  return (
    <div ref={containerRef}>
      {!hideHeader && <Header />}
      <MultiStepAnswer
        questionWrapperComponent={questionWrapperComponent}
        showFirstQueryTitle={showFirstQueryTitle}
        questionQueryTitleClass={questionQueryTitleClass}
        showQuestionFooter={showQuestionFooter}
      />
      {isAnswerLoading && (
        <div className="suggested-answer-body">
          <SkeletonLoader lines={3} />
        </div>
      )}
      {!hideSuggestedQuestions &&
        suggestedFollowupQuestions &&
        suggestedFollowupQuestions.length > 0 && (
          <>
            <hr />
            {suggestedFollowupQuestions.map((q) => (
              <span
                className="followup-link"
                data-testid="followup-link"
                key={q}
                onClick={() => onFollowupClicked(q)}
              >
                <span className="link-text">{q}</span>
              </span>
            ))}
          </>
        )}
    </div>
  );
};

export default AiAnswer;
