import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

interface TicketFieldsProps {
  externalId: string;
  isFilterable: boolean;
}

async function updateTicketFields({
  externalId,
  isFilterable
}: TicketFieldsProps): Promise<void> {
  const url = `${getBackendApiUrl()}/tickets/ticket-fields`;
  const options = patch({ externalId, isFilterable });
  return request(url, options).then((response) => response);
}

export { updateTicketFields };
