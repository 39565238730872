import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { NavLink, Route } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { Routes } from "../../../routes/routes";
import styles from "./Filters.module.scss";
import { FiltersNavigation } from "./FiltersNavigation";
import { HiddenFilters } from "./HiddenFilters";
import { ProductFilters } from "./ProductFilters";
import { QuickFilters } from "./QuickFilters";
import { TicketFieldsSettings } from "./TicketFieldsSettings";

const Filters: React.FC = () => {
  const { user } = useAuth();
  const { flags } = useFlags();
  const filterTabs = [
    {
      label: "Products",
      icon: <AeIcons.ProductFilters />,
      show: flags.aptAnswersProductFiltersUi && !!user?.admin,
      path: Routes.SETTINGS_FILTERS_PRODUCT,
      cmp: <ProductFilters />
    },
    {
      label: "Quick filters",
      icon: <AeIcons.QuickFilters />,
      show: flags.aptAnswersQuickFiltersUi,
      path: Routes.SETTINGS_FILTERS_QUICK,
      cmp: <QuickFilters />
    },
    {
      label: "Hidden results",
      icon: <AeIcons.HiddenFilters />,
      show: flags.aptAnswersHiddenFiltersUi && !!user?.admin,
      path: Routes.SETTINGS_FILTERS_HIDDEN,
      cmp: <HiddenFilters />
    }
  ];
  const showFiltersNavigation = filterTabs.filter((tab) => tab.show).length > 1;
  const showTicketFields = flags.ticketFieldsSettingsUi && !!user?.admin;

  const defaultFiltersPath = Routes.SETTINGS_FILTERS;
  const defaultFilterTab = filterTabs.find((tab) => tab.show);
  if (defaultFilterTab) {
    defaultFilterTab.path = defaultFiltersPath;
  }

  return (
    <div className={styles.container}>
      <div>
        {showFiltersNavigation ? (
          <div className={styles.navigationContainer}>
            <FiltersNavigation filterTabs={filterTabs} />
          </div>
        ) : null}
        {showTicketFields && (
          <>
            <div className={styles.divider} />
            <NavLink
              to={Routes.SETTINGS_TICKET_FIELDS}
              exact={true}
              className={styles.navigationItemcontainer}
              activeClassName={styles.active}
            >
              <span className={styles.icon}>
                <AEIcon
                  name="settings"
                  className={styles.ticketFieldSettingsIcon}
                  size="1.25rem"
                />
              </span>
              <span
                className={styles.label}
                data-testid="ticket-fields-settings"
              >
                Ticket field settings
              </span>
            </NavLink>
          </>
        )}
      </div>
      <div className={styles.contentContainer}>
        {filterTabs.map((tab) => {
          return tab.show ? (
            <Route key={tab.path} exact={true} path={tab.path}>
              {tab.cmp}
            </Route>
          ) : null;
        })}
        {flags.ticketFieldsSettingsUi && (
          <Route exact={true} path={Routes.SETTINGS_TICKET_FIELDS}>
            <TicketFieldsSettings />
          </Route>
        )}
      </div>
    </div>
  );
};

export { Filters };
