import { createSlice } from "@reduxjs/toolkit";
import { ConfigResponse } from "../../types/selfService";

type SliceStateType = {
  cssConfig: ConfigResponse | null;
  user: {
    email: string;
    name: string;
    extraDetails?: Record<string, string>;
  };
};

const initialState: SliceStateType = {
  cssConfig: {} as ConfigResponse,
  user: {
    email: "",
    name: ""
  }
};

const selfServiceSlice = createSlice({
  name: "selfService",
  initialState,
  reducers: {
    updateCssConfig: (state, action) => {
      state.cssConfig = action.payload;
    },
    setF1User: (state, action) => {
      state.user = action.payload;
    }
  }
});

export const { updateCssConfig, setF1User } = selfServiceSlice.actions;

export default selfServiceSlice.reducer;
