import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import { TicketFields } from "@aptedge/lib-ui/src/types/entities";
import {
  ChangeEventHandler,
  RefObject,
  useEffect,
  useRef,
  useState
} from "react";
import { useMutation, useQuery } from "react-query";
import { WebCacheKey } from "../clients/cache";
import { fetchTicketFields } from "../clients/Tickets/fetchTicketFields";
import { updateTicketFields } from "../clients/Tickets/updateTicketFields";

type UseTicketFieldsProps = {
  isLoading: boolean;
  containerRef: RefObject<HTMLDivElement>;
  popupOpen: boolean;
  ticketFields: TicketFields[];
  textInput: string;
  isMaxTicketFieldSelected: boolean;
  selectedTicketFieldsCount: number;
  handleClear: () => void;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleTicketFieldSelection: (externalId: string) => void;
  togglePopup: () => void;
};

function useTicketFields(): UseTicketFieldsProps {
  const [popupOpen, setPopupOpen] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [ticketFields, setTicketFields] = useState<TicketFields[]>([]);
  const [originalTicketFields, setOriginalTicketFields] = useState<
    TicketFields[]
  >([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const ticketFieldsQuery = useQuery([WebCacheKey.TICKET_FIELDS], () =>
    fetchTicketFields()
  );

  const { data: ticketFieldsData, isLoading } = ticketFieldsQuery;

  const selectedTicketFieldsCount = ticketFields.filter(
    (fields) => fields.isFilterable === true
  ).length;

  const isMaxTicketFieldSelected = Boolean(selectedTicketFieldsCount === 25);

  const updateFields = useMutation(updateTicketFields);

  const handleTicketFieldSelection = (externalId: string): void => {
    if (ticketFields) {
      const newTicketFields = [...ticketFields];
      const updatedTicketFields = [];

      for (let i = 0; i < newTicketFields.length; i++) {
        const fields = newTicketFields[i];
        if (fields.externalId === externalId) {
          fields.isFilterable = !fields.isFilterable;
          updateFields.mutate({
            externalId: externalId,
            isFilterable: fields.isFilterable
          });
        }
        updatedTicketFields.push(fields);
      }

      updatedTicketFields.sort(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (a: any, b: any) => b.isFilterable - a.isFilterable
      );
      setTicketFields(updatedTicketFields);
    }
  };

  const normalizeString = (text: string): string => {
    return text.toLowerCase().replace(/\s+/g, " ").trim();
  };

  // This function creates a quick lookup table using externalId and updates the list which will be displayed in the dropdown.
  // It handles scenarios where the user clears the input using the cancel icon or manually deletes the search text,
  // ensuring that the selected fields are sorted to the top of the list.
  const getUpdatedTicketFields = (): TicketFields[] => {
    const ticketFieldsMap = new Map();

    ticketFields.forEach((fields) => {
      ticketFieldsMap.set(fields.externalId, fields.isFilterable);
    });

    const updatedTicketFields = originalTicketFields.map((updatedFields) => {
      if (ticketFieldsMap.has(updatedFields.externalId)) {
        updatedFields.isFilterable =
          ticketFieldsMap.get(updatedFields.externalId) || 0;
      }
      return updatedFields;
    });

    return updatedTicketFields.sort(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (a: any, b: any) => b.isFilterable - a.isFilterable
    );
  };

  const updateTicketFieldsOnInputChange = (inputText: string): void => {
    let newTicketFields: TicketFields[] = [];
    if (Boolean(inputText)) {
      newTicketFields = originalTicketFields.filter((fields) =>
        inputText
          .toLowerCase()
          .split(" ")
          .every((word) => normalizeString(fields.name).includes(word))
      );
    } else {
      newTicketFields = getUpdatedTicketFields();
    }
    setTicketFields(newTicketFields);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e): void => {
    setTextInput(e.target.value);
    updateTicketFieldsOnInputChange(e.target.value);
  };

  const handleClear = (): void => {
    setTextInput("");
    const updatedTicketFields = getUpdatedTicketFields();
    setTicketFields(updatedTicketFields);
  };

  const togglePopup = (): void => {
    setPopupOpen(!popupOpen);
  };

  useOnMouseDownOutside(containerRef, () => setPopupOpen(false));

  useEffect(() => {
    if (ticketFieldsData) {
      const updatedTicketFields = ticketFieldsData.sort(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (a: any, b: any) => b.isFilterable - a.isFilterable
      );
      setTicketFields(updatedTicketFields);
      setOriginalTicketFields(updatedTicketFields);
    }
  }, [ticketFieldsData]);

  return {
    isLoading,
    containerRef,
    popupOpen,
    ticketFields,
    textInput,
    isMaxTicketFieldSelected,
    selectedTicketFieldsCount,
    handleClear,
    handleInputChange,
    handleTicketFieldSelection,
    togglePopup
  };
}

export { useTicketFields };
