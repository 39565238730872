/* istanbul ignore file */

export enum Routes {
  HOME = "/",
  LOGIN = "/login",
  LOGOUT = "/logout",
  RECOVERY = "/recovery",
  RESET = "/reset",
  WELCOME = "/welcome",
  FEED = "/feed",
  EDGES = "/edges",
  EDGES_EDGE = "/edges/:edgeId",
  ANALYTICS = "/analytics",
  ARTICLES = "/articles",
  ARTICLES_PENDING = "/articles/pending",
  ARTICLES_REVIEW = "/articles/review",
  SETTINGS = "/settings",
  SETTINGS_APPS = "/settings/apps",
  SETTINGS_MACHINE_LEARNING = "/settings/machine-learning",
  SETTINGS_WORKFLOW = "/settings/workflow",
  SETTINGS_STATUS = "/settings/status",
  SETTINGS_FILTERS = "/settings/filters",
  SETTINGS_FILTERS_HIDDEN = "/settings/filters/hidden",
  SETTINGS_FILTERS_PRODUCT = "/settings/filters/product",
  SETTINGS_FILTERS_QUICK = "/settings/filters/quick",
  SETTINGS_MY_PRODUCTS = "/settings/my-products",
  SETTINGS_TICKET_FIELDS = "/settings/filters/ticket-fields",
  USER_SETTINGS = "/user-settings",
  TICKETS = "/tickets",
  TICKET = "/tickets/:ticketId",
  GLOBAL_SEARCH = "/search",
  GLOBAL_OAUTH = "/global-oauth/:provider",
  ADMIN_DASHBOARD = "/settings/admin-dashboard",
  CSS_ANALYTICS = "/settings/css-admin-dashboard",
  CSS_CONFIG = "/settings/css-config",
  DOCUMENT_FILES = "/edges/:sourcetype/files/:fileId"
}
