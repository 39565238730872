export const resetSessionStorage = (): void => {
  console.log("Resetting session storage");
  window.sessionStorage.removeItem("aptedgeTour");
};

export enum MessageTypes {
  NextStep = "nextStep",
  CssConfig = "cssConfig",
  UsageLimit = "usageLimit",
  AptEdgeStatus = "aptedgeStatus",
  ExpandSearch = "expandSearch",
  BackToStart = "backToStart",
  HighlightElement = "highlightElement",
  DisableHighlight = "disableHighlight",
  HideModal = "hideModal",
  ShowLoading = "showLoading",
  FatalError = "fatalError",
  ExpandedPage = "expandedPage",
  ChangeHeight = "changeHeight",
  getSearchQuery = "getSearchQuery",
  searchQuery = "searchQuery",
  createTicket = "createTicket",
  getUser = "getUser",
  flags = "flags"
}
type MessageData = Partial<Record<MessageTypes, unknown>>;

export const sendMessage = (data: MessageData, target = "*"): void => {
  const parentApp = window.parent;
  parentApp.postMessage(data, target);
};
