import { SDKClient } from "@aptedge/lib-support-ui/src/clients/sdkClient";
import useMappedResults from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/useMappedResults";
import Body from "@aptedge/lib-ui/src/features/AiAnswer/components/Body/Body";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  AnswerQuestion,
  AnswerAction
} from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import React, { FC } from "react";
import "./MultiStepAnswer.scss";
import QuestionFooter from "../../../EmbeddedSearchV2/SearchResultPage/QuestionFooter";

interface Props {
  truncateLongAnswer?: boolean;
  resultsMap?: Map<string, ICompositeResult>;
  client?: SDKClient;
  aptEdgeBaseUrl?: string;
  questionWrapperComponent?: React.ElementType;
  showFirstQueryTitle?: boolean;
  questionQueryTitleClass?: string;
  showQuestionFooter?: boolean;
}
const MultiStepAnswer: FC<Props> = ({
  truncateLongAnswer,
  resultsMap,
  client,
  aptEdgeBaseUrl,
  questionWrapperComponent,
  showFirstQueryTitle,
  questionQueryTitleClass,
  showQuestionFooter
}) => {
  const { mappedResults, totalSearchResults } = useMappedResults();
  const { answer } = useAppSelector((state) => state.answerGPT);

  const renderNewSearches = (
    actions: AnswerAction[],
    index: number
  ): JSX.Element | undefined => {
    if (index === 0) {
      return;
    }

    const newSearches = actions[index - 1].newSearches;
    if (newSearches) {
      return <i>Searching for {newSearches.join(", ")}</i>;
    }
  };

  const Wrapper = questionWrapperComponent ?? React.Fragment;

  return (
    <>
      {answer.map((question: AnswerQuestion, i: number) => (
        <div key={i}>
          {(showFirstQueryTitle || i > 0) && (
            <h2 className={questionQueryTitleClass}>{question.query}</h2>
          )}
          {question.actions.map((action: AnswerAction, j: number) => (
            <div key={j}>
              {j > 0 && (
                <>
                  <hr />
                  {renderNewSearches(question.actions, j)}
                </>
              )}
              {action.answer && (
                <Wrapper>
                  <Body
                    answer={action.answer}
                    resultsMap={resultsMap ?? mappedResults}
                    totalResults={totalSearchResults}
                    truncateLongAnswer={truncateLongAnswer}
                    client={client}
                    aptEdgeBaseUrl={aptEdgeBaseUrl}
                  />
                </Wrapper>
              )}
            </div>
          ))}
          {showQuestionFooter && (
            <QuestionFooter
              questionIndex={i}
              questionText={question.query}
              showFeedback={i === answer.length - 1}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default MultiStepAnswer;
