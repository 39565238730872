/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  useAppSelector,
  useAppDispatch
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateAnswer,
  updateAnswerLoadingState,
  updateIsAnswerLoaded
} from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { setIsSearchLoading } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  ApiAnswer,
  GQADocSearchResult
} from "@aptedge/lib-ui/src/types/entities";
import { toUIErrorMessage } from "@aptedge/lib-ui/src/utils/errors";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";
import { UseInfiniteQueryResult, useInfiniteQuery } from "react-query";
import { makeAnswerAPICallArgs } from "./useAiAnswerQuery";

export const useAiAnswerQueryCall = (
  enabled: boolean,
  streamingAnswers: boolean,
  answerId: string,
  jsonMode: boolean,
  makeAnswerAPICall: (
    args: makeAnswerAPICallArgs
  ) => Promise<TimedResponse<GQADocSearchResult>>,
  createCacheKey: () => string[],
  createSearchParams: (
    pageParam: number,
    answerRequested: boolean
  ) => makeAnswerAPICallArgs,
  onSearchStarted?: (answerRequested: boolean) => void,
  onAnswerStarted?: () => void,
  onSearchResultsReceived?: (
    lastPage: TimedResponse<GQADocSearchResult>
  ) => void,
  onAnswerUpdated?: (answer: ApiAnswer) => void,
  onAnswerFinished?: (answer: ApiAnswer) => void
): UseInfiniteQueryResult<TimedResponse<GQADocSearchResult>> => {
  const { searchQuery } = useAppSelector((state) => state.search);
  const cacheKey = createCacheKey();
  const dispatch = useAppDispatch();
  // react-query requires a Boolean value for `enabled`, not just a falsy value
  const queryEnabled = Boolean(enabled && searchQuery);
  const answerRequested = Boolean(answerId);
  const gptAnswer = useInfiniteQuery(
    cacheKey,
    ({ pageParam = 0 }) => {
      const searchParams = createSearchParams(pageParam, answerRequested);

      if (!pageParam) {
        onSearchStarted && onSearchStarted(answerRequested);
        if (answerRequested) {
          onAnswerStarted && onAnswerStarted();
        }
      }

      return makeAnswerAPICall(searchParams);
    },
    {
      getNextPageParam: () => {
        return 1;
      },

      // react-query requires a Boolean value for `enabled`, not just a falsy value
      enabled: queryEnabled,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,

      // setting cacheTime and staleTime to infinity ensures that the query is not re-fetched when
      // the app is disabled and re-enabled, which happens when collapsing and expanding it in
      // the Zendesk or Salesforce UI
      cacheTime: Infinity,
      staleTime: Infinity,

      onError: (error) => {
        console.error("Error making answer call", error);

        if (answerRequested) {
          dispatch(
            updateAnswer([
              {
                query: searchQuery,
                actions: [
                  {
                    query: searchQuery,
                    answer: toUIErrorMessage(error),
                    newSearches: []
                  }
                ]
              }
            ])
          );
          dispatch(updateAnswerLoadingState(false));
          dispatch(updateIsAnswerLoaded(true));
        }
      },

      onSuccess: (response) => {
        dispatch(setIsSearchLoading(false));

        const lastPage = response.pages[response.pages.length - 1];
        const {
          data: { answer, items }
        } = lastPage;

        if (items !== undefined) {
          onSearchResultsReceived && onSearchResultsReceived(lastPage);
        }

        if (answerRequested) {
          if (answer) {
            onAnswerUpdated && onAnswerUpdated(answer);
          }

          const questionsCount = answer?.questions
            ? answer.questions.length
            : 0;
          const lastQuestionStatus =
            answer?.questions[questionsCount - 1]?.status;
          if (
            lastQuestionStatus === "SUCCESS" ||
            lastQuestionStatus === "ERROR"
          ) {
            onAnswerFinished && onAnswerFinished(answer as ApiAnswer);
          } else {
            setTimeout(() => {
              if (queryEnabled) {
                gptAnswer.fetchNextPage();
              }
            }, 200);
          }
        }
      }
    }
  );

  return gptAnswer;
};
