import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import {
  updateAdditionalInstruction,
  updateAnswer,
  updateAnswerLoadingState,
  updateShowMoreButtonClicked,
  updateAnswerCardVisibility,
  updateIsAnswerLoaded
} from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  ISearchFilter,
  QUERY_PARAMS
} from "@aptedge/lib-ui/src/types/entities";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { GTM_EVENTS, dataLayerPush } from "../../../../src/utils/event";
import { isSupportApp } from "../../../../src/utils/supportApp";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/hook";
import {
  resetSearch,
  updateAnswerId,
  updateAutoSearchActive,
  updateHasLinkedEdge,
  updatePage,
  updateProductFilters,
  updateQuickFilters,
  updateSearchCardVisibility,
  updateSearchQuery,
  updateShowSuggestion,
  updateTotalSearchResults
} from "../../../redux/reduxSlice/searchSlice";
import useSearchSuggestion from "./useSearchSuggestion";

type InputElement = HTMLTextAreaElement | HTMLInputElement;
interface IUseSearch {
  searchQuery: string;
  onInputChange: (event: ChangeEvent<InputElement>) => void;
  onEnterPress: () => void;
  onCancelClick: () => void;
  searchFilter: ISearchFilter;
  onBackBtnPress: () => void;
}

const triggerClearSearchEvent = (
  ticketSubject: string,
  ticketId: string
): void => {
  const eventData = isSupportApp
    ? {
        ticket_title: ticketSubject,
        ticket_id: ticketId
      }
    : null;
  dataLayerPush({
    event: GTM_EVENTS.GTM_CLICK_CLEAR_SEARCH,
    data: eventData
  });
};

const useSearch = (
  /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  suggestions?: any
): IUseSearch => {
  const { flags } = useFlags();
  const { searchQuery: query, searchFilter, myProducts } = useAppSelector(
    (state) => state.search
  );

  const { ticket } = useAppSelector((state) => state.cookedTicket);
  const { ticketId, ticketSubject } = ticket;
  const [searchQuery, setSearchQuery] = useState<string>("");

  const {
    clearAllQueryParams,
    removeQueryParams,
    history,
    queryParams
  } = useQueryParams();

  const dispatch = useAppDispatch();
  const location = useLocation();

  const onBackBtnPress = (): void => {
    if (location.pathname.includes("search-suggestion")) history.goBack();
    else history.push("/");
    setSearchQuery("");
  };

  useSearchSuggestion(searchQuery, searchFilter, suggestions, true);

  const shouldUpdateAnswerId =
    flags.alwaysAnswerGPT || searchQuery.endsWith("?");

  const addAnswerId = (): void => {
    const answerId = uuidv4();
    queryParams.set(QUERY_PARAMS.ANSWER_ID, answerId);
    dispatch(updateAnswerId(answerId));
    history.push(`?${queryParams.toString()}`);
  };

  const removeAnswerId = (): void => {
    queryParams.delete(QUERY_PARAMS.ANSWER_ID);
    dispatch(updateAnswerId(""));
    history.push(`?${queryParams.toString()}`);
  };

  /* This function is triggered when we press the Enter key in the search input to set the answerId in the query parameters. */
  const updateQueryParamsWithAnswerId = (): void => {
    queryParams.set(QUERY_PARAMS.QUERY, searchQuery.trim());
    if (shouldUpdateAnswerId) {
      /* This condition ensures that the answerId is pushed to the query parameters along with the searchquery only if there is a change in the searchquery.
        It updates the Redux state accordingly in the function called. If the user clicks Enter without changing the searchquery, this action is ignored. */
      if (query !== searchQuery) addAnswerId();
    } else {
      /* This condition ensures that if there is an answerId in the query parameters and the user changes the query to a normal query without including "?",
        then the answerId is removed from the query parameters, and the Redux state is updated accordingly in the function called. */
      if (queryParams.has(QUERY_PARAMS.ANSWER_ID)) {
        removeAnswerId();
      } else if (query !== searchQuery) {
        /* This condition ensures that if there is any change in the search query and there is no answerId, then the query parameters are updated with the new search query.
          It also ignores any action if the user clicks Enter without changing the search query. */
        history.push(`?${queryParams.toString()}`);
      }
    }
  };

  const onInputChange = (event: ChangeEvent<InputElement>): void => {
    const { value: query } = event?.target;
    setSearchQuery(query);
    dispatch(updateHasLinkedEdge(false));
    dispatch(updateShowSuggestion(true));
    dispatch(updateTotalSearchResults(0));
    dispatch(updateAutoSearchActive(false));
  };
  const onEnterPress = (): void => {
    dispatch(updateSearchQuery(searchQuery.trim()));
    dispatch(updateAdditionalInstruction(""));
    removeQueryParams(QUERY_PARAMS.ADDITIONAL_INSTRUCTIONS);
    removeQueryParams(QUERY_PARAMS.RESULT_ID);
    dispatch(updateSearchCardVisibility(false));
    dispatch(updatePage(1));
    dispatch(updateShowSuggestion(false));
    dispatch(updateAutoSearchActive(false));
    dispatch(updateShowMoreButtonClicked(false));
    dispatch(updateAnswer([]));
    updateQueryParamsWithAnswerId();
  };

  const onCancelClick = (): void => {
    setSearchQuery("");
    dispatch(resetSearch());
    dispatch(updateAnswer([]));
    dispatch(updateAnswerLoadingState(false));
    dispatch(updateIsAnswerLoaded(false));
    dispatch(updateAnswerId(""));
    dispatch(updateShowMoreButtonClicked(false));
    dispatch(updateAnswerCardVisibility(false));
    dispatch(updateQuickFilters([]));
    dispatch(updateProductFilters(myProducts));
    clearAllQueryParams();
    triggerClearSearchEvent(ticketSubject, ticketId);
  };

  useEffect(() => {
    if (query) {
      setSearchQuery(query);
    }
  }, [query]);

  return {
    searchQuery,
    searchFilter,
    onInputChange,
    onEnterPress,
    onCancelClick,
    onBackBtnPress
  };
};

export default useSearch;
