import { FlagOverridesProvider } from "@aptedge/lib-ui/src/context/FlagsContext/FlagOverridesContext";
import { WelcomePage } from "@aptedge/lib-ui/src/features/WelcomePage/WelcomePage";
import React, { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import { F1AppInWebApp } from "../components/F1AppInWebApp/F1AppInWebApp";
import { FeatureFlagsProvider } from "../components/FeatureFlags/FeatureFlagsProvider";
import { NavBar } from "../components/NavigationBar/NavBar";
import { Analytics } from "../pages/Analytics/Analytics";
import MSOAuth from "../pages/GlobalOAuth/GlobalOAuth";
import GlobalSearchPage from "../pages/GlobalSearch/GlobalSearchPage";
import { HomePage } from "../pages/HomePage/HomePage";
import { LoginPage } from "../pages/LoginPage/LoginPage";
import { LogoutPage } from "../pages/LoginPage/LogoutPage";
import { LoginRecoveryPage } from "../pages/LoginRecoveryPage/LoginRecoveryPage";
import { LoginResetPage } from "../pages/LoginResetPage/LoginResetPage";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import { SettingsPage } from "../pages/SettingsPage/SettingsPage";
import { TicketListingPage } from "../pages/TicketListingPage/TicketListingPage";
import { TicketPage } from "../pages/TicketPage/TicketPage";
import { UserSettingsPage } from "../pages/UserSettingsPage/UserSettingsPage";
import { ArticlesRoutes } from "./ArticleRoutes";
import { EdgeRoutes } from "./EdgeRoutes";
import { OnboardedRoute } from "./OnboardedRoute";
import { PrivateRoute } from "./PrivateRoute";
import { Routes } from "./routes";

const AppRoutes = (): ReactElement => {
  return (
    <Switch>
      <Route exact path={Routes.LOGIN} component={LoginPage} />
      <Route exact path={Routes.LOGOUT} component={LogoutPage} />
      <Route exact path={Routes.RECOVERY} component={LoginRecoveryPage} />
      <Route exact path={Routes.RESET} component={LoginResetPage} />
      <Route exact path={Routes.GLOBAL_OAUTH} component={MSOAuth} />

      {/* All Private Routes should be under one */}
      <PrivateRoute>
        <FlagOverridesProvider>
          <FeatureFlagsProvider>
            <F1AppInWebApp>
              <NavBar />
              <Switch>
                <Route>
                  <Switch>
                    <Route
                      exact
                      path={Routes.GLOBAL_SEARCH}
                      component={GlobalSearchPage}
                    />
                    <Route
                      exact
                      path={Routes.WELCOME}
                      component={WelcomePage}
                    />
                    <Route exact path={Routes.HOME} component={HomePage} />
                    <OnboardedRoute path={Routes.EDGES}>
                      <EdgeRoutes />
                    </OnboardedRoute>
                    <OnboardedRoute exact path={Routes.TICKETS}>
                      <TicketListingPage />
                    </OnboardedRoute>
                    <OnboardedRoute exact path={Routes.TICKET}>
                      <TicketPage />
                    </OnboardedRoute>
                    <OnboardedRoute path={Routes.ARTICLES}>
                      <ArticlesRoutes />
                    </OnboardedRoute>
                    <OnboardedRoute path={Routes.ANALYTICS}>
                      <Analytics />
                    </OnboardedRoute>
                    <Route path={Routes.SETTINGS} component={SettingsPage} />
                    <OnboardedRoute exact path={Routes.USER_SETTINGS}>
                      <UserSettingsPage />
                    </OnboardedRoute>
                    <Route>
                      <NotFoundPage />
                    </Route>
                  </Switch>
                </Route>
              </Switch>
            </F1AppInWebApp>
          </FeatureFlagsProvider>
        </FlagOverridesProvider>
      </PrivateRoute>
    </Switch>
  );
};

export { AppRoutes };
