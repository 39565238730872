import { AnswerQuestion } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { SearchFilters } from "@aptedge/lib-ui/src/services/search/searchFilterService";
import {
  GQADocSearchResult,
  ArticleSupport,
  CookedTicket,
  EdgeSupport,
  UrlReferenceSupport,
  TemplateSupport,
  TemplateLocation,
  CompositeResultSupport,
  EdgeResultSupport,
  TicketResultSupport,
  ArticleResultSupport,
  IssueResultSupport,
  IssueSupport,
  ISocialSupport,
  ARTICLE_SOURCE_TYPE,
  DOCUMENT_SOURCE_TYPE,
  SuggestionsResultsSupport,
  DocumentResultSupport,
  EntityType,
  ISSUES_SOURCE_TYPE,
  ITicketInfo,
  ISearchFilter,
  FederatedSupport,
  FEDERATED_SOURCE_TYPE,
  SearchFilterSubType,
  ISocialResult,
  ITicketResult,
  ITicketListing,
  IEdgeListing,
  IEdgeStatus,
  IUserListing,
  IGeneratedKnowledgeResult,
  ArticleStatus,
  ResultOffsetType,
  GENERATED_KNOWLEDGE_SOURCE_TYPE,
  GeneratedKnowledgeTicketId,
  IDocumentResult,
  ApiChatMessageSender,
  ApiChatStatus
} from "@aptedge/lib-ui/src/types/entities";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";

const MOCK_EDGE_1: EdgeSupport = {
  id: 1,
  name: "Cannot login",
  richSummary: { version: "markdown.0.1", content: "Something happened" },
  status: "NEW",
  tickets: [
    { id: 1, externalId: "1" },
    { id: 2, externalId: "2" }
  ],
  assignee: { id: 1, email: "jane@aptedge.io" },
  links: [{ url: "https://aptedge.io", urlReference: 1 }],
  archived: false,
  labels: [{ id: 1, name: "Auth", type: EntityType.THEME }],
  createdOn: 1653638970,
  updatedOn: 1653638970,
  preview: "<em>login</em>",
  offset: {} as ResultOffsetType
};

const MOCK_EDGE_4: EdgeResultSupport = {
  id: 1,
  name: "Cannot login",
  richSummary: { version: "markdown.0.1", content: "Something happened" },
  status: "NEW",
  tickets: [
    { id: 1, externalId: "1" },
    { id: 2, externalId: "2" }
  ],
  assignee: { id: 1, email: "jane@aptedge.io" },
  links: [{ url: "https://aptedge.io", urlReference: 1 }],
  archived: false,
  labels: [{ id: 1, name: "Auth", type: EntityType.THEME }],
  createdOn: 1653638970,
  updatedOn: 1653638970,
  preview: "<em>login</em>",
  ticketCount: 4,
  type: "edge",
  offset: {} as ResultOffsetType
};
const MOCK_EDGE_5: EdgeResultSupport = {
  id: 1,
  name: "Cannot login",
  richSummary: { version: "markdown.0.1", content: "Something happened" },
  status: "NEW",
  tickets: [
    { id: 1, externalId: "1" },
    { id: 2, externalId: "2" }
  ],
  assignee: { id: 1, email: "jane@aptedge.io" },
  links: [{ url: "https://aptedge.io", urlReference: 1 }],
  archived: true,
  labels: [{ id: 1, name: "Auth", type: EntityType.THEME }],
  createdOn: 1653638970,
  updatedOn: 1653638970,
  ticketCount: 4,
  type: "edge",
  preview: "<em>login</em>",
  offset: {} as ResultOffsetType
};

const MOCK_EDGE_2: EdgeSupport = {
  id: 2,
  name: "Something is borked",
  richSummary: { version: "markdown.0.1", content: "Something happened" },
  status: "NEW",
  tickets: [
    { id: 1, externalId: "1" },
    { id: 2, externalId: "2" }
  ],
  assignee: null,
  links: [{ url: "https://aptedge.io", urlReference: 1 }],
  archived: false,
  labels: [{ id: 1, name: "Bug", type: EntityType.THEME }],
  createdOn: 1653638970,
  updatedOn: 1653638970,
  preview: "<em>borked</em>",
  offset: {} as ResultOffsetType
};

const MOCK_EDGE_3: EdgeSupport = {
  id: 3,
  name: "Cannot reset password",
  richSummary: { version: "markdown.0.1", content: "Something happened" },
  status: "NEW",
  tickets: [],
  assignee: null,
  links: [{ url: "https://aptedge.io", urlReference: 1 }],
  archived: false,
  labels: [{ id: 1, name: "Article", type: EntityType.THEME }],
  createdOn: 1653638970,
  updatedOn: 1653638970,
  preview: "<em>reset</em>",
  offset: {} as ResultOffsetType
};

const MOCK_EDGE_LIST: EdgeSupport[] = [MOCK_EDGE_1, MOCK_EDGE_2, MOCK_EDGE_3];

const MOCK_EDGE_WEB_1: IEdgeListing = {
  id: 1,
  name: "Cannot login",
  richSummary: { version: "markdown.0.1", content: "Something happened" },
  status: IEdgeStatus.NEW,
  tickets: [
    { id: 1, externalId: "1" },
    { id: 2, externalId: "2" }
  ],
  assignee: { id: 1, email: "jane@aptedge.io" },
  links: [{ url: "https://aptedge.io", urlReference: 1 }],
  archived: false,
  labels: [{ id: 1, name: "Auth", type: EntityType.THEME }],
  createdOn: 1653638970,
  updatedOn: 1653638970,
  preview: "<em>login</em>",
  offset: {} as ResultOffsetType,
  displayName: "",
  internalIssues: [],
  summary: "",
  pointOfContact: "",
  comments: [],
  createdBy: {} as IUserListing,
  userUpdatedBy: null,
  userUpdatedOn: null,
  team: null,
  space: undefined,
  revenueUsd: null,
  filters: [],
  isAutoreplyEnabled: false,
  isAutolinkEnabled: false,
  url: ""
};

const MOCK_EDGE_WEB_2: IEdgeListing = {
  id: 2,
  name: "Something is borked",
  richSummary: { version: "markdown.0.1", content: "Something happened" },
  status: IEdgeStatus.NEW,
  tickets: [
    { id: 1, externalId: "1" },
    { id: 2, externalId: "2" }
  ],
  assignee: null,
  links: [{ url: "https://aptedge.io", urlReference: 1 }],
  archived: false,
  labels: [{ id: 1, name: "Bug", type: EntityType.THEME }],
  createdOn: 1653638970,
  updatedOn: 1653638970,
  preview: "<em>borked</em>",
  offset: {} as ResultOffsetType,
  displayName: "",
  internalIssues: [],
  summary: "",
  pointOfContact: "",
  comments: [],
  createdBy: {} as IUserListing,
  userUpdatedBy: null,
  userUpdatedOn: null,
  team: null,
  space: undefined,
  revenueUsd: null,
  filters: [],
  isAutoreplyEnabled: false,
  isAutolinkEnabled: false,
  url: ""
};

const MOCK_EDGE_WEB_3: IEdgeListing = {
  id: 3,
  name: "Cannot reset password",
  richSummary: { version: "markdown.0.1", content: "Something happened" },
  status: IEdgeStatus.NEW,
  tickets: [],
  assignee: null,
  links: [{ url: "https://aptedge.io", urlReference: 1 }],
  archived: false,
  labels: [{ id: 1, name: "Article", type: EntityType.THEME }],
  createdOn: 1653638970,
  updatedOn: 1653638970,
  preview: "<em>reset</em>",
  offset: {} as ResultOffsetType,
  displayName: "",
  internalIssues: [],
  summary: "",
  pointOfContact: "",
  comments: [],
  createdBy: {} as IUserListing,
  userUpdatedBy: null,
  userUpdatedOn: null,
  team: null,
  space: undefined,
  revenueUsd: null,
  filters: [],
  isAutoreplyEnabled: false,
  isAutolinkEnabled: false,
  url: ""
};

const MOCK_EDGE_WEB_LIST: IEdgeListing[] = [
  MOCK_EDGE_WEB_1,
  MOCK_EDGE_WEB_2,
  MOCK_EDGE_WEB_3
];

const MOCK_GENERATED_KNOWLEDGE: IGeneratedKnowledgeResult = {
  id: "1234",
  title: "Test generated knowledge title",
  body: { body: "Test generated knowledge body" },
  createdOn: Date.now(),
  updatedOn: Date.now(),
  preview: "<em>test</em>",
  sourceType: GENERATED_KNOWLEDGE_SOURCE_TYPE.GENERATED_KNOWLEDGE,
  url: "",
  offset: {} as ResultOffsetType,
  status: ArticleStatus.DRAFT,
  type: GENERATED_KNOWLEDGE_SOURCE_TYPE.GENERATED_KNOWLEDGE,
  ticketIds: {} as GeneratedKnowledgeTicketId
};

const MOCK_DOCUMENT: IDocumentResult = {
  id: "1234",
  title: "Test document title",
  body: "Test dcoument body",
  createdOn: Date.now(),
  updatedOn: Date.now(),
  preview: "<em>test</em>",
  sourceType: DOCUMENT_SOURCE_TYPE.SHAREPOINT_DRIVE,
  url: "",
  offset: {} as ResultOffsetType,
  type: "document",
  sourceId: "",
  displayUrl: ""
};

const MOCK_SOCIAL_1: ISocialSupport = {
  sourceType: "slack",
  channel: "xyz",
  createdOn: 1654078746,
  displayUrl: "https://example.slack.com/archives/123",
  id: "dfd2c30ea5e34b7db00e0490516a1a8c",
  preview: "<em>Coffee</em> Talk",
  title: "Coffee Talk",
  type: "social",
  updatedOn: 1654078746,
  url: "https://example.slack.com/archives/123",
  offset: {} as ResultOffsetType
};
const MOCK_SOCIAL_3: ISocialSupport = {
  sourceType: "microsoft-teams",
  channel: "MS-Teams",
  createdOn: 1654078746,
  displayUrl: "https://example.slack.com/archives/123",
  id: "dfd2c30ea5e34b7db00e0490516a1a8c",
  preview: "<em>Coffee</em> Talk in MS teams",
  title: "Coffee Talk MS Teams",
  type: "social",
  updatedOn: 1654078746,
  url: "https://example.slack.com/archives/123",
  offset: {} as ResultOffsetType
};
const MOCK_SOCIAL_2: ISocialSupport = {
  sourceType: "slack",
  channel: "xyz",
  createdOn: 1654078749,
  displayUrl: "https://example.slack.com/archives/123",
  id: "dfd2c30ea5e34b7db00e0490516a1a8c",
  preview: "<em>AptEdge</em> meetup",
  title: "AptEdge meetup",
  type: "social",
  updatedOn: 1654078746,
  url: "https://example.slack.com/archives/123",
  offset: {} as ResultOffsetType
};

const MOCK_SOCIAL_4: ISocialResult = {
  sourceType: "slack",
  channel: "xyz",
  createdOn: 1654078749,
  displayUrl: "https://example.slack.com/archives/123",
  id: "dfd2c30ea5e34b7db00e0490516a1a8c",
  preview: "<em>AptEdge</em> meetup",
  title: "AptEdge meetup",
  type: "social",
  updatedOn: 1654078746,
  url: "https://example.slack.com/archives/123",
  offset: {} as ResultOffsetType
};

const MOCK_SOCIAL_5: ISocialResult = {
  sourceType: "microsoft-teams",
  channel: "MS-Teams",
  createdOn: 1654078746,
  displayUrl: "https://example.slack.com/archives/123",
  id: "dfd2c30ea5e34b7db00e0490516a1a8c",
  preview: "<em>Coffee</em> Talk in MS teams",
  title: "",
  type: "social",
  updatedOn: 1654078746,
  url: "https://example.slack.com/archives/123",
  offset: {} as ResultOffsetType
};

const MOCK_SOCIAL_6: ISocialResult = {
  sourceType: "microsoft-teams",
  channel: "",
  createdOn: 1654078746,
  displayUrl: "https://example.slack.com/archives/123",
  id: "dfd2c30ea5e34b7db00e0490516a1a8c",
  preview: "<em>Coffee</em> Talk in MS teams",
  title: "",
  type: "social",
  updatedOn: 1654078746,
  url: "https://example.slack.com/archives/123",
  offset: {} as ResultOffsetType
};

const MOCK_DOCUMENT_1: DocumentResultSupport = {
  sourceType: DOCUMENT_SOURCE_TYPE.EDGE,
  body: "Some body of pdf",
  displayUrl: "https://example.aptedge.io/edge/123",
  createdOn: 1654078746,
  url: "https://example.aptedge.io/edge/123",
  id: "dfd2c30ea5e34b7db00e0490516a1a8c",
  preview: "<em>Document sample</em> pdf",
  title: "edge-attachment.pdf",
  type: "document",
  sourceId: "123",
  updatedOn: 1654078746,
  offset: {} as ResultOffsetType
};
const MOCK_DOCUMENT_2: DocumentResultSupport = {
  sourceType: DOCUMENT_SOURCE_TYPE.SHAREPOINT_DRIVE,
  body: "Some other body of pdf 2",
  createdOn: 1654078776,
  url: "https://sharepoint.com/asd/123",
  displayUrl: "https://sharepoint.com/asd/123",
  id: "dfd2c30ea5e34b7db00e0490516a1a9c",
  preview: "<em>Document sample 2</em> pdf",
  title: "sharepoint-pdf.pdf",
  sourceId: "123",
  type: "document",
  updatedOn: 1654078776,
  offset: {} as ResultOffsetType
};

const MOCK_COOKED_TICKET: CookedTicket = {
  id: 1,
  externalId: "107",
  displayId: "24",
  subject: "Everything is broke",
  description: "Something happened",
  status: "new",
  edges: [],
  products: [],
  features: [],
  customer: { name: "Jon Doe" },
  themes: [],
  createdOn: 0,
  updatedOn: 0,
  frustration: null,
  urlReferences: null,
  assigneeUser: null,
  preview: "",
  offset: {} as ResultOffsetType
};

const MOCK_COOKED_TICKET_2: CookedTicket = {
  id: 3,
  externalId: "109",
  displayId: "28",
  subject: "Everything is broke",
  description: "Something happened",
  status: "new",
  edges: [],
  products: [],
  features: [],
  customer: { name: "Jon Doe" },
  themes: [],
  createdOn: 0,
  updatedOn: 0,
  frustration: null,
  urlReferences: null,
  assigneeUser: null,
  preview: "",
  offset: {} as ResultOffsetType
};

const MOCK_LINKED_TICKET: CookedTicket = {
  ...MOCK_COOKED_TICKET,
  id: 2,
  externalId: "218",
  subject: "Seriously, everything is broken",
  description: "WUT"
};

const MOCK_TICKET_DEFAULT: ITicketInfo = {
  closedOn: 1654078746,
  comments: [],
  fields: null,
  monetaryValue: null,
  url: "https://help.okta.com/hc/en-us/articles/6001",
  priority: "high",
  requesterUser: null,
  ticketSource: 0,
  edgesMetadata: [],
  id: 40,
  subject: "Ticket Page test",
  externalId: "",
  displayId: "",
  createdOn: 1654078746,
  updatedOn: 1654078746,
  status: "",
  customer: null,
  products: [],
  features: [],
  themes: [],
  description: "",
  summary: null,
  frustration: null,
  urlReferences: null,
  edges: [],
  assigneeUser: null,
  offset: {} as ResultOffsetType
};

const MOCK_EXTERNAL_TICKET_DEFAULT: ITicketInfo = {
  closedOn: 1654078746,
  comments: [],
  fields: null,
  monetaryValue: null,
  url: "https://help.okta.com/hc/en-us/articles/6001",
  priority: "high",
  requesterUser: null,
  ticketSource: 0,
  edgesMetadata: [],
  id: 1,
  subject: "Ticket Page test",
  externalId: "1234",
  displayId: "1234",
  createdOn: 1654078746,
  updatedOn: 1654078746,
  status: "",
  customer: null,
  products: [],
  features: [],
  themes: [],
  description: "",
  summary: null,
  frustration: null,
  urlReferences: null,
  edges: [],
  assigneeUser: null,
  offset: {} as ResultOffsetType
};

const MOCK_TICKET_LIST: CookedTicket[] = [
  MOCK_COOKED_TICKET,
  MOCK_LINKED_TICKET,
  MOCK_COOKED_TICKET_2
];

const MOCK_TICKET: ITicketListing = {
  id: 123,
  subject: "Mock Testing",
  externalId: "",
  displayId: "",
  url: "https://help.okta.com/hc/en-us/articles/6001",
  // -1 means ticket is still open
  closedOn: 1654078746,
  createdOn: 1654078746,
  updatedOn: 1654078746,
  priority: "",
  status: "open",
  customer: null,
  products: [],
  features: [],
  themes: [],
  description: "",
  summary: null,
  requesterUser: null,
  ticketSource: 0,
  frustration: null,
  urlReferences: null,
  edges: [],
  assigneeUser: null,
  edgesMetadata: [],
  offset: {} as ResultOffsetType
};

const MOCK_TICKET_RESULT: ITicketResult = {
  ...MOCK_TICKET,
  type: "ticket"
};

const MOCK_URL_REFERENCE_1: UrlReferenceSupport = {
  id: 1,
  domain: "help.okta.com",
  productIssueId: "OKTA-1",
  url: "https://help.okta.com/hc/en-us/articles/6001"
};

const MOCK_URL_REFERENCE_2: UrlReferenceSupport = {
  id: 2,
  domain: "help.okta.com",
  productIssueId: "OKTA-2",
  url: "https://help.okta.com/hc/en-us/articles/6002"
};

const MOCK_ARTICLE_1: ArticleSupport = {
  id: "abc",
  title: "Remediation for X",
  url: "https://help.okta.com/hc/en-us/articles/6003",
  createdOn: 1654078746,
  updatedOn: 1654078746,
  sourceType: ARTICLE_SOURCE_TYPE.CONFLUENCE,
  displayUrl: "https://help.okta.com/hc/en-us/articles/6003",
  preview: "<em>Remediation</em>",
  offset: {} as ResultOffsetType
};
const MOCK_ARTICLE_4: ArticleResultSupport = {
  id: "abc",
  title: "Remediation for X",
  url: "https://help.okta.com/hc/en-us/articles/6003",
  createdOn: 1654078746,
  sourceType: ARTICLE_SOURCE_TYPE.ZENDESK,
  updatedOn: 1654078746,
  displayUrl: "https://help.okta.com/hc/en-us/articles/6003",
  type: "article",
  preview: "<em>Remediation</em>",
  offset: {} as ResultOffsetType
};

const MOCK_ARTICLE_2: ArticleSupport = {
  id: "abc",
  title: "Remediation for Y",
  url: "https://help.okta.com/hc/en-us/articles/6004",
  createdOn: 1654078746,
  sourceType: ARTICLE_SOURCE_TYPE.CONFLUENCE,
  updatedOn: 1654078746,
  displayUrl: "https://help.okta.com/hc/en-us/articles/6004",
  preview: "<em>Remediation</em>",
  offset: {} as ResultOffsetType
};
const MOCK_ARTICLE_3: ArticleSupport = {
  createdOn: 1654078746,
  displayUrl: "https://help.okta.com/hc/en-us/articles/display/4001",
  id: "cdd9525b21664a1187a4a87b0bef339c",
  preview: "<em>How</em>-To",
  sourceType: ARTICLE_SOURCE_TYPE.CONFLUENCE,
  title: "How-To",
  updatedOn: 1654078746,
  url: "https://help.okta.com/hc/en-us/articles/4001",
  offset: {} as ResultOffsetType
};

const MOCK_FEDERATED_1: FederatedSupport = {
  id: "abc",
  title: "Remediation for X",
  url: "https://help.okta.com/hc/en-us/articles/6003",
  createdOn: 1654078746,
  updatedOn: 1654078746,
  sourceType: FEDERATED_SOURCE_TYPE.FEDERATED,
  displayUrl: "https://help.okta.com/hc/en-us/articles/6003",
  preview: "<em>Remediation</em>",
  offset: {} as ResultOffsetType
};

const MOCK_ISSUE_1: IssueSupport = {
  id: "1",
  title: "Test issue",
  url: "www.google.com",
  description: "dummy description",
  created_on: 1449944,
  updated_on: 383838,
  updatedOn: 383838,
  displayUrl: "",
  preview: "<em>test</em>",
  sourceType: ISSUES_SOURCE_TYPE.AZURE_DEVOPS,
  offset: {} as ResultOffsetType
};
const MOCK_ISSUE_2: IssueSupport = {
  id: "2",
  title: "Some issue",
  url: "www.google.com",
  description: "dummy description",
  created_on: 1449944,
  updated_on: 383838,
  updatedOn: 383838,
  displayUrl: "",
  preview: "<em>Some</em>",
  sourceType: ISSUES_SOURCE_TYPE.JIRA,
  offset: {} as ResultOffsetType
};
const MOCK_ISSUE_3: IssueResultSupport = {
  createdOn: 1658412333,
  id: "67648598574241d6b132eea1b173295c",
  preview: "It's <em>broken</em>.",
  sourceType: ISSUES_SOURCE_TYPE.AZURE_DEVOPS,
  title: "Fix this",
  type: "issue",
  updatedOn: 1658412333,
  url: "https://okta.atlassian.net/browse/OKTA-101",
  displayUrl: "https://okta.atlassian.net/browse/OKTA-101",
  offset: {} as ResultOffsetType
};

const MOCK_TEMPLATE: TemplateSupport = {
  id: 1,
  location: TemplateLocation.TICKET_DESCRIPTION,
  content: "Hello, this is a template",
  version: "markdown.0.1"
};

const MOCK_DATA = {
  ticket: {
    id: 1,
    description: "Something went wrong.",
    updatedAt: "Tue May 19 2020 09:42:17 GMT-0700 (Pacific Daylight Time)",
    status: "solved",
    subject: "Uh oh"
  },
  currentUser: { email: "pfarnsworth@planetexpress.earth" }
};

const MOCK_COMPOSITE_RESULTS: CompositeResultSupport[] = [
  { ...MOCK_EDGE_1, type: "edge" },
  { ...MOCK_COOKED_TICKET, type: "ticket" },
  { ...MOCK_COOKED_TICKET_2, type: "ticket" },
  { ...MOCK_EDGE_2, type: "edge" },
  { ...MOCK_LINKED_TICKET, type: "ticket" }
];

const MOCK_EDGE_RESULTS: EdgeResultSupport[] = [
  { ...MOCK_EDGE_1, type: "edge" },
  { ...MOCK_EDGE_2, type: "edge" },
  { ...MOCK_EDGE_3, type: "edge" }
];

const MOCK_SOCIAL_RESULTS: ISocialSupport[] = [
  { ...MOCK_SOCIAL_1, type: "social" },
  { ...MOCK_SOCIAL_2, type: "social" }
];

const MOCK_DOCUMENT_RESULTS: DocumentResultSupport[] = [
  { ...MOCK_DOCUMENT_1, type: "document" },
  { ...MOCK_DOCUMENT_1, type: "document" }
];

const MOCK_TICKET_RESULTS: TicketResultSupport[] = [
  { ...MOCK_COOKED_TICKET, type: "ticket" },
  { ...MOCK_COOKED_TICKET_2, type: "ticket" },
  { ...MOCK_LINKED_TICKET, type: "ticket" }
];

const MOCK_ARTICLE_RESULTS: ArticleResultSupport[] = [
  { ...MOCK_ARTICLE_1, type: "article" },
  { ...MOCK_ARTICLE_2, type: "article" }
];
const MOCK_ARTICLE_RESULTS_WITH_SOURCE: ArticleResultSupport[] = [
  { ...MOCK_ARTICLE_3, type: "article" }
];
const MOCK_ISSUE_RESULTS: IssueResultSupport[] = [
  { ...MOCK_ISSUE_1, type: "issue" },
  { ...MOCK_ISSUE_2, type: "issue" }
];

const MOCK_SEARCH_SUGGESTION: SuggestionsResultsSupport = {
  suggestions: []
};
const MOCK_ANSWER_ENGINE: TimedResponse<GQADocSearchResult> = {
  data: {
    answer: {
      questions: [
        {
          query: "Test query",
          actions: [
            {
              content: {
                text: "This is a detailed test answer"
              },
              query: "Test query",
              initiator: ApiChatMessageSender.HUMAN,
              status: ApiChatStatus.SUCCESS
            }
          ],
          status: ApiChatStatus.SUCCESS
        }
      ],
      timers: {
        b: 456
      }
    },
    items: [],
    timers: {
      c: 789
    },
    total: 0,
    totalPages: 1,
    hasNext: false,
    page: 1,
    perPage: 10
  },
  startTimeMillis: 1,
  endTimeMillis: 2
};

type DynamicFilters = {
  groups: SearchFilters[];
};
const MOCK_DYNAMIC_FILTERS: DynamicFilters = {
  groups: [
    {
      label: "Edges",
      icon: "",
      key: "edges",
      filter_key: ISearchFilter.EDGES,
      filters: undefined
    },
    {
      label: "Tickets",
      icon: "",
      key: "tickets",
      filter_key: ISearchFilter.TICKETS,
      filters: undefined
    },
    {
      label: "Knowledge Base",
      icon: "",
      key: "knowledge-base",
      filter_key: ISearchFilter.ARTICLE,
      filters: [
        {
          label: "Notion",
          icon: "",
          filter_key: "notion" as SearchFilterSubType
        },
        {
          label: "Sharepoint",
          icon: "",
          filter_key: "sharepoint" as SearchFilterSubType
        },
        {
          label: "Confluence",
          icon: "",
          filter_key: "confluence" as SearchFilterSubType
        }
      ]
    },
    {
      label: "Issues",
      icon: "",
      key: "issues",
      filter_key: ISearchFilter.ISSUES,
      filters: []
    },
    {
      label: "Social",
      icon: "",
      key: "social",
      filter_key: ISearchFilter.SOCIAL,
      filters: [
        {
          label: "MS Teams",
          icon: "",
          filter_key: "microsoft-teams" as SearchFilterSubType
        }
      ]
    }
  ]
};
const MOCK_GPT_ANSWER: AnswerQuestion[] = [
  {
    query: "test query",
    actions: [
      {
        query: "subquery 1",
        answer:
          "It depends on what is broken. Please provide more information about what needs to be fixed. SOURCES: https://okta.atlassian.net/browse/OKTA-101",
        newSearches: []
      }
    ]
  }
];

const MOCK_AUTOSEARCH_QUERY = {
  response: "This is a sample autosearch query."
};

const MOCK_KB_ARTICLE = {
  response: "This is a sample kb-article query."
};

const MOCK_TICKET_SUMMARY = {
  response: "This is a sample ticket summary.",
  latencyMillis: 0
};

export const MOCK_CSS_CONFIG = {
  title: "Test title",
  ticket_creation: {
    url: "www.example.com",
    link_text: "Test link"
  },
  searches_remaining: 0,
  usage_limit: 1000,
  resource_list: {
    enabled: true,
    links: [{ title: "example", url: "www.example.com", description: "desc" }]
  },
  proactive_answers: {
    enabled: true,
    search_input_selector: "",
    page_globs: [],
    user_auth_required: false
  },
  internal_config: {
    dashboard_link: ""
  }
};

export {
  MOCK_EDGE_1,
  MOCK_EDGE_2,
  MOCK_EDGE_3,
  MOCK_EDGE_4,
  MOCK_EDGE_5,
  MOCK_EDGE_LIST,
  MOCK_EDGE_WEB_LIST,
  MOCK_COOKED_TICKET,
  MOCK_COOKED_TICKET_2,
  MOCK_LINKED_TICKET,
  MOCK_TICKET_LIST,
  MOCK_URL_REFERENCE_1,
  MOCK_URL_REFERENCE_2,
  MOCK_TEMPLATE,
  MOCK_DATA,
  MOCK_COMPOSITE_RESULTS,
  MOCK_EDGE_RESULTS,
  MOCK_TICKET_RESULTS,
  MOCK_ARTICLE_RESULTS,
  MOCK_FEDERATED_1,
  MOCK_ISSUE_1,
  MOCK_ISSUE_2,
  MOCK_ISSUE_3,
  MOCK_ISSUE_RESULTS,
  MOCK_SEARCH_SUGGESTION,
  MOCK_GENERATED_KNOWLEDGE,
  MOCK_DOCUMENT,
  MOCK_SOCIAL_1,
  MOCK_SOCIAL_2,
  MOCK_SOCIAL_3,
  MOCK_SOCIAL_4,
  MOCK_SOCIAL_5,
  MOCK_SOCIAL_6,
  MOCK_SOCIAL_RESULTS,
  MOCK_ARTICLE_1,
  MOCK_ARTICLE_3,
  MOCK_ARTICLE_4,
  MOCK_ARTICLE_RESULTS_WITH_SOURCE,
  MOCK_TICKET_DEFAULT,
  MOCK_EXTERNAL_TICKET_DEFAULT,
  MOCK_ANSWER_ENGINE,
  MOCK_GPT_ANSWER,
  MOCK_DOCUMENT_1,
  MOCK_DOCUMENT_2,
  MOCK_DOCUMENT_RESULTS,
  MOCK_AUTOSEARCH_QUERY,
  MOCK_KB_ARTICLE,
  MOCK_TICKET_SUMMARY,
  MOCK_DYNAMIC_FILTERS,
  MOCK_TICKET_RESULT
};
