import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { IFlagSet } from "@aptedge/lib-ui/src/context/FlagsContext/types";
import { useAiAnswerQueryQuestion } from "@aptedge/lib-ui/src/features/Search/hooks/useAiAnswerQueryQuestion";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateAnswerCardVisibility } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  setIsSearchFocused,
  updateAnswerId,
  updatePage,
  updateSearchCardVisibility,
  updateSelectedSearchResult
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  UserSupport,
  QUERY_PARAMS,
  ICompositeResult
} from "@aptedge/lib-ui/src/types/entities";
import { isWebApp } from "@aptedge/lib-ui/src/utils/event";
import { RefObject, useRef, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { gqaSearch } from "../../../clients/CompositeSearch/gqaSearch";
import { useWhoami } from "../../../clients/User/useWhoami";
import useArrowNavigationSL, {
  IUseArrowNavigationSL
} from "../../../components/GlobalSearch/GlobalSearchList/hooks/useArrowNavigationSL";
import { triggerWebSearchPaginationEvent } from "../../../components/GlobalSearch/GlobalSearchList/utils/events";

interface IUseGlobalSearch {
  flags: IFlagSet;
  searchRef: RefObject<HTMLInputElement>;
  refetchAnswer: () => void;
  searchContainerRef: RefObject<HTMLDivElement>;
  isSearchListRefActive: boolean;
  searchResultsArrowNavigation: Omit<
    IUseArrowNavigationSL,
    "searchContainerRef"
  >;
  handleActiveRefForSearchList: (val: boolean) => void;
  userData?: UserSupport;
  isLoading: boolean;
  showFeedback: boolean;
  paginationAnalyticsEnabled: boolean;
  page: number;
  totalPages: number;
  total: number;
  isSearchLoading: boolean;
  searchQuery: string;
  answerId: string;
  handlePaginationChange: ({ page }: { page: number }) => void;
  searchResults: ICompositeResult[];
}
const useGlobalSearch = (): IUseGlobalSearch => {
  const { flags } = useFlags();
  const { queryParams, updateParams, removeQueryParams } = useQueryParams();
  const searchRef = useRef<HTMLInputElement>(null);
  const [isSearchListRefActive, setIsSearchListRefActive] = useState<boolean>(
    true
  );
  const [isSearchResultPreviewed, setIsSearchResultPreviewed] = useState<
    boolean
  >(true);
  const { data: userData, isLoading } = useWhoami();

  const {
    page,
    searchQuery,
    searchResults,
    searchResultsInfo,
    isSearchLoading,
    answerId,
    searchFilter,
    resultId
  } = useAppSelector((state) => state.search);

  const { answer } = useAppSelector((state) => state.answerGPT);

  const selectedSourceResultId = queryParams.get(QUERY_PARAMS.RESULT_ID);
  const isUpdateAnswerId = queryParams.get(QUERY_PARAMS.UPDATE_ANSWER_ID);

  // WARNING: Be careful calling this function in multiple places! See comment on
  // useAiAnswerQuery().
  const { refetchAnswer } = useAiAnswerQueryQuestion(
    true,
    flags.streamingAnswers,
    gqaSearch
  );

  const handleActiveRefForSearchList = (val: boolean): void => {
    setIsSearchListRefActive(val);
    if (!val) {
      window.scrollTo({
        top: -60,
        behavior: "smooth"
      });
    }
  };

  const dispatch = useAppDispatch();

  const hasSearchResultsOrAnswer = Boolean(
    searchQuery &&
      !isSearchLoading &&
      (Boolean(searchResults.length > 0) || answer)
  );

  const showFeedback = isWebApp || hasSearchResultsOrAnswer;

  const total = searchResultsInfo?.total ?? 0;
  const totalPages = searchResultsInfo?.totalPages ?? 1;

  const paginationAnalyticsEnabled = (!!total && total > 0) ?? 0;
  const shouldUpdateAnswerId = Boolean(
    isUpdateAnswerId && searchQuery && selectedSourceResultId
  );
  const shouldUpdateSelectedSearchResult =
    (selectedSourceResultId || resultId) &&
    searchResults &&
    searchResults.length > 0;

  const handlePaginationChange = ({ page }: { page: number }): void => {
    updateParams(QUERY_PARAMS.PAGE, page.toString());
    dispatch(updatePage(page));
    dispatch(updateSearchCardVisibility(false));
    dispatch(updateAnswerCardVisibility(false));
    triggerWebSearchPaginationEvent(searchQuery, searchFilter, page);
  };

  useEffect(() => {
    const handleSearchRefFocus = (): void => {
      dispatch(setIsSearchFocused(true));
    };

    const handleSearchRefBlur = (): void => {
      dispatch(setIsSearchFocused(false));
    };

    const searchInput = searchRef.current;
    searchInput?.addEventListener("focus", handleSearchRefFocus);
    searchInput?.addEventListener("blur", handleSearchRefBlur);

    if (shouldUpdateAnswerId) {
      const answerId = uuidv4();
      dispatch(updateAnswerId(answerId));
      removeQueryParams(QUERY_PARAMS.UPDATE_ANSWER_ID);
      updateParams(QUERY_PARAMS.ANSWER_ID, answerId.toString());
    }

    if (shouldUpdateSelectedSearchResult) {
      const updatedSelectedItem = searchResults.find(
        (result: ICompositeResult) => {
          const id = result.id.toString();
          return id === selectedSourceResultId || id === resultId;
        }
      );

      if (updatedSelectedItem?.id) {
        dispatch(updateSelectedSearchResult(updatedSelectedItem));
        if (isSearchResultPreviewed) {
          dispatch(updateAnswerCardVisibility(false));
          setIsSearchResultPreviewed(false);
        }
      }
    }

    return () => {
      searchInput?.removeEventListener("focus", handleSearchRefFocus);
      searchInput?.removeEventListener("blur", handleSearchRefBlur);
    };
  }, [
    dispatch,
    searchResults,
    selectedSourceResultId,
    shouldUpdateSelectedSearchResult,
    removeQueryParams,
    shouldUpdateAnswerId,
    updateParams,
    isSearchResultPreviewed,
    resultId
  ]);

  const {
    searchContainerRef,
    ...searchResultsArrowNavigation
  } = useArrowNavigationSL(page);
  return {
    flags,
    searchRef,
    refetchAnswer,
    userData,
    isLoading,
    searchContainerRef,
    isSearchListRefActive,
    searchResultsArrowNavigation,
    handleActiveRefForSearchList,
    showFeedback,
    paginationAnalyticsEnabled,
    page,
    totalPages,
    total,
    isSearchLoading,
    searchQuery,
    answerId,
    handlePaginationChange,
    searchResults
  };
};

export default useGlobalSearch;
